export default {
  PERM_VIEW_MODULE_VEHICLES: "Accéder au module véhicule",
  PERM_VIEW_ANY_VEHICLES: "Voir tous les véhicules",
  PERM_VIEW_VEHICLES: "Voir les détails d'un véhicule",
  PERM_CREATE_VEHICLES: "Créer un véhicule",
  PERM_EDIT_VEHICLES: "Modifier les véhicules",
  PERM_DELETE_VEHICLES: "Supprimer un véhicule",

  PERM_VIEW_MODULE_VEHICLE_ESTIMATIONS:
    "Accéder au module d'évaluation du prix de véhicule",
  PERM_MAKE_API_VEHICLE_ESTIMATIONS:
    "Faire une évaluation du prix de véhicule via l'api",
  PERM_MAKE_IFRAME_VEHICLE_ESTIMATIONS:
    "Faire une évaluation du prix de véhicule via l'iFrame",
  PERM_VIEW_VEHICLE_ESTIMATIONS_DEBUG_MODE:
    "Acceder au mode debug des evaluation du prix de véhicule",

  PERM_VIEW_ANY_VEHICLE_ESTIMATIONS_DOMAINS: "Voir tous les domaines",
  PERM_VIEW_VEHICLE_ESTIMATIONS_DOMAINS: "Voir les détails d'un domaine",
  PERM_CREATE_VEHICLE_ESTIMATIONS_DOMAINS: "Ajouter un domaine",
  PERM_EDIT_VEHICLE_ESTIMATIONS_DOMAINS: "Modifier un domaine",
  PERM_DELETE_VEHICLE_ESTIMATIONS_DOMAINS: "Supprimer un domaine",

  PERM_VIEW_MODULE_REQUESTS: "Accéder au module demande",
  PERM_VIEW_ANY_REQUESTS: "Voir toutes les demandes",
  PERM_VIEW_REQUESTS: "Voir les détails d'une demande",
  PERM_CREATE_REQUESTS: "Créer une demande",
  PERM_EDIT_REQUESTS: "Modifier les demandes",
  PERM_DELETE_REQUESTS: "Supprimer une demande",
  PERM_APPROVE_REQUESTS: "Approuver les demandes",
  PERM_ASSIGN_REQUESTS: "Assigner les demandes",
  PERM_CANCEL_REQUESTS: "Annuler les demandes",
  PERM_COMPLETE_REQUESTS: "Compléter les demandes",
  PERM_MAKE_INSPECTIONS_REQUESTS: "Faire des demandes d'inspection",
  PERM_MAKE_REPARATIONS_REQUESTS: "Faire des demandes de réparation",
  PERM_MAKE_ESTIMATIONS_REQUESTS: "Faire des demandes d'estimation",
  PERM_MAKE_EVALUATION_REQUESTS: "Faire des demandes d'estimation",

  PERM_VIEW_MODULE_INSPECTIONS: "Accéder au module inspection",
  PERM_VIEW_ANY_INSPECTIONS: "Voir toutes les inspections",
  PERM_VIEW_INSPECTIONS: "Voir les détails d'une inspection",
  PERM_VIEW_ASSIGNED_TO_GROUP_INSPECTIONS:
    "Voir les inspections assignées à son groupe",
  PERM_CREATE_INSPECTIONS: "Créer une inspection",
  PERM_EDIT_INSPECTIONS: "Modifier les inspections",
  PERM_DELETE_INSPECTIONS: "Supprimer une inspection",
  PERM_APPROVE_INSPECTIONS: "Valider les inspections",
  PERM_ASSIGN_INSPECTIONS: "Assigner les inspections",
  PERM_CANCEL_INSPECTIONS: "Annuler les inspections",
  PERM_START_INSPECTIONS: "Démarrer une inspection",
  PERM_COMPLETE_INSPECTIONS: "Compléter les inspections",
  PERM_TAKE_OVER_INSPECTIONS: "S’approprier une inspection",
  PERM_VIEW_IN_PROGRESS_INSPECTIONS: "Voir les inspections en cours",

  PERM_VIEW_MODULE_INSPECTION_FORMS: "Accéder au module formulaires",
  PERM_VIEW_ANY_INSPECTION_FORMS: "Voir tous les formulaires",
  PERM_VIEW_INSPECTION_FORMS: "Voir les détails d'un formulaire",
  PERM_CREATE_INSPECTION_FORMS: "Créer un formulaire",
  PERM_EDIT_INSPECTION_FORMS: "Modifier les formulaires",
  PERM_DELETE_INSPECTION_FORMS: "Supprimer un formulaire",

  PERM_VIEW_MODULE_IMPORTS: "Accéder au module import",
  PERM_VIEW_ANY_IMPORTS: "Voir tous les imports",
  PERM_VIEW_IMPORTS: "Voir les détails d'un import",
  PERM_CREATE_IMPORTS: "Créer un import",
  PERM_EDIT_IMPORTS: "Modifier les import",
  PERM_DELETE_IMPORTS: "Supprimer un import",

  PERM_VIEW_MODULE_STOCK_MOVEMENTS: "Accéder au module mouvements de stock",
  PERM_VIEW_ANY_STOCK_MOVEMENTS: "Voir tous les mouvements de stock",
  PERM_VIEW_STOCK_MOVEMENTS: "Voir les détails d'un mouvement de stock",
  PERM_CREATE_STOCK_MOVEMENTS: "Créer un mouvement de stock",
  PERM_EDIT_STOCK_MOVEMENTS: "Modifier les mouvements de stock",
  PERM_DELETE_STOCK_MOVEMENTS: "Supprimer un mouvement de stock",

  PERM_VIEW_MODULE_WAREHOUSES: "Accéder au module entrepôts",
  PERM_VIEW_ANY_WAREHOUSES: "Voir tous les entrepôts",
  PERM_VIEW_WAREHOUSES: "Voir les détails d'un entrepôt",
  PERM_CREATE_WAREHOUSES: "Créer un entrepôt",
  PERM_EDIT_WAREHOUSES: "Modifier les entrepôts",
  PERM_DELETE_WAREHOUSES: "Supprimer un entrepôt",

  PERM_VIEW_MODULE_RESELLER_PAYMENTS: "Accéder au module paiements revendeur",
  PERM_VIEW_ANY_RESELLER_PAYMENTS: "Voir tous les paiements revendeur",
  PERM_VIEW_RESELLER_PAYMENTS: "Voir les détails d'un paiement revendeur",
  PERM_CREATE_RESELLER_PAYMENTS: "Créer un paiement revendeur",
  PERM_EDIT_RESELLER_PAYMENTS: "Modifier les paiements revendeur",
  PERM_DELETE_RESELLER_PAYMENTS: "Supprimer un paiement revendeur",

  PERM_VIEW_MODULE_RESELLER_INVOICES: "Accéder au module factures revendeur",
  PERM_VIEW_ANY_RESELLER_INVOICES: "Voir toutes les factures revendeur",
  PERM_VIEW_RESELLER_INVOICES: "Voir les détails d'une facture revendeur",
  PERM_CREATE_RESELLER_INVOICES: "Créer une facture revendeur",
  PERM_EDIT_RESELLER_INVOICES: "Modifier les factures revendeur",
  PERM_DELETE_RESELLER_INVOICES: "Supprimer une facture revendeur",

  PERM_VIEW_MODULE_RESELLER_SERVICES: "Accéder au module services revendeur",
  PERM_VIEW_ANY_RESELLER_SERVICES: "Voir tous les services revendeur",
  PERM_VIEW_RESELLER_SERVICES: "Voir les détails d'un service revendeur",
  PERM_CREATE_RESELLER_SERVICES: "Créer un service revendeur",
  PERM_EDIT_RESELLER_SERVICES: "Modifier les services revendeur",
  PERM_DELETE_RESELLER_SERVICES: "Supprimer un service revendeur",

  PERM_VIEW_MODULE_RESELLER_PRODUCTS: "Accéder au module produits revendeur",
  PERM_VIEW_ANY_RESELLER_PRODUCTS: "Voir tous les produits revendeur",
  PERM_VIEW_RESELLER_PRODUCTS: "Voir les détails d'un produit revendeur",
  PERM_CREATE_RESELLER_PRODUCTS: "Créer un produit revendeur",
  PERM_EDIT_RESELLER_PRODUCTS: "Modifier les produits revendeur",
  PERM_DELETE_RESELLER_PRODUCTS: "Supprimer un produit revendeur",

  PERM_VIEW_MODULE_SALES_PAYMENTS: "Accéder au module paiements vente",
  PERM_VIEW_ANY_SALES_PAYMENTS: "Voir tous les paiements vente",
  PERM_VIEW_SALES_PAYMENTS: "Voir les détails d'un paiement vente",
  PERM_CREATE_SALES_PAYMENTS: "Créer un paiement vente",
  PERM_EDIT_SALES_PAYMENTS: "Modifier les paiements vente",
  PERM_DELETE_SALES_PAYMENTS: "Supprimer un paiement vente",

  PERM_VIEW_MODULE_SALES_DELIVERIES: "Accéder au module livraisons vente",
  PERM_VIEW_ANY_SALES_DELIVERIES: "Voir toutes les livraisons vente",
  PERM_VIEW_SALES_DELIVERIES: "Voir les détails d'une livraison vente",
  PERM_CREATE_SALES_DELIVERIES: "Créer une livraison vente",
  PERM_EDIT_SALES_DELIVERIES: "Modifier les livraisons vente",
  PERM_DELETE_SALES_DELIVERIES: "Supprimer une livraison vente",

  PERM_VIEW_MODULE_SALES_INVOICES: "Accéder au module factures vente",
  PERM_VIEW_ANY_SALES_INVOICES: "Voir toutes les factures vente",
  PERM_VIEW_SALES_INVOICES: "Voir les détails d'une facture vente",
  PERM_CREATE_SALES_INVOICES: "Créer une facture vente",
  PERM_EDIT_SALES_INVOICES: "Modifier les factures vente",
  PERM_DELETE_SALES_INVOICES: "Supprimer une facture vente",

  PERM_VIEW_MODULE_SALES_ORDERS: "Accéder au module commandes vente",
  PERM_VIEW_ANY_SALES_ORDERS: "Voir toutes les commandes vente",
  PERM_VIEW_SALES_ORDERS: "Voir les détails d'une commande vente",
  PERM_CREATE_SALES_ORDERS: "Créer une commande vente",
  PERM_EDIT_SALES_ORDERS: "Modifier les commandes vente",
  PERM_DELETE_SALES_ORDERS: "Supprimer une commande vente",

  PERM_VIEW_MODULE_PURCHASES_PAYMENTS: "Accéder au module paiements achat",
  PERM_VIEW_ANY_PURCHASES_PAYMENTS: "Voir tous les paiements achat",
  PERM_VIEW_PURCHASES_PAYMENTS: "Voir les détails d'un paiement achat",
  PERM_CREATE_PURCHASES_PAYMENTS: "Créer un paiement achat",
  PERM_EDIT_PURCHASES_PAYMENTS: "Modifier les paiements achat",
  PERM_DELETE_PURCHASES_PAYMENTS: "Supprimer un paiement achat",

  PERM_VIEW_MODULE_PURCHASES_DELIVERIES: "Accéder au module livraisons achat",
  PERM_VIEW_ANY_PURCHASES_DELIVERIES: "Voir toutes les livraisons achat",
  PERM_VIEW_PURCHASES_DELIVERIES: "Voir les détails d'une livraison achat",
  PERM_CREATE_PURCHASES_DELIVERIES: "Créer une livraison achat",
  PERM_EDIT_PURCHASES_DELIVERIES: "Modifier les livraisons achat",
  PERM_DELETE_PURCHASES_DELIVERIES: "Supprimer une livraison achat",

  PERM_VIEW_MODULE_PURCHASES_INVOICES: "Accéder au module factures achat",
  PERM_VIEW_ANY_PURCHASES_INVOICES: "Voir toutes les factures achat",
  PERM_VIEW_PURCHASES_INVOICES: "Voir les détails d'une facture achat",
  PERM_CREATE_PURCHASES_INVOICES: "Créer une facture achat",
  PERM_EDIT_PURCHASES_INVOICES: "Modifier les factures achat",
  PERM_DELETE_PURCHASES_INVOICES: "Supprimer une facture achat",

  PERM_VIEW_MODULE_PURCHASES_ORDERS: "Accéder au module commandes achat",
  PERM_VIEW_ANY_PURCHASES_ORDERS: "Voir toutes les commandes achat",
  PERM_VIEW_PURCHASES_ORDERS: "Voir les détails d'une commande achat",
  PERM_CREATE_PURCHASES_ORDERS: "Créer une commande achat",
  PERM_EDIT_PURCHASES_ORDERS: "Modifier les commandes achat",
  PERM_DELETE_PURCHASES_ORDERS: "Supprimer une commande achat",

  PERM_VIEW_MODULE_FOLDERS: "Accéder au module dossiers",
  PERM_VIEW_ANY_FOLDERS: "Voir tous les dossiers",
  PERM_VIEW_FOLDERS: "Voir les détails d'un dossier",
  PERM_CREATE_FOLDERS: "Créer un dossier",
  PERM_EDIT_FOLDERS: "Modifier les dossiers",
  PERM_DELETE_FOLDERS: "Supprimer un dossier",
  PERM_MANAGE_ACCESS_FOLDERS: "Gérer les permissions",
  PERM_MANAGE_LOCKED_FOLDERS: "Gérer les dossiers systèmes",

  PERM_VIEW_MODULE_FILES: "Accéder au module fichiers",
  PERM_VIEW_ANY_FILES: "Voir tous les fichiers",
  PERM_VIEW_FILES: "Voir les détails d'un fichier",
  PERM_CREATE_FILES: "Créer un fichier",
  PERM_EDIT_FILES: "Modifier les fichiers",
  PERM_DELETE_FILES: "Supprimer un fichier",
  PERM_MANAGE_ACCESS_FILES: "Gérer les permissions",

  PERM_VIEW_MODULE_LOCATIONS: "Accéder au module concessions",
  PERM_VIEW_ANY_LOCATIONS: "Voir tous concessions",
  PERM_VIEW_LOCATIONS: "Voir les détails d'une concession",
  PERM_CREATE_LOCATIONS: "Créer une concession",
  PERM_EDIT_LOCATIONS: "Modifier les concessions",
  PERM_EDIT_HIS_LOCATIONS: "Modifier sa concession",
  PERM_DELETE_LOCATIONS: "Supprimer une concession",

  PERM_VIEW_MODULE_SUPPLIERS: "Accéder au module fournisseurs",
  PERM_VIEW_ANY_SUPPLIERS: "Voir tous fournisseurs",
  PERM_VIEW_SUPPLIERS: "Voir les détails d'un fournisseur",
  PERM_CREATE_SUPPLIERS: "Créer un fournisseur",
  PERM_EDIT_SUPPLIERS: "Modifier les fournisseurs",
  PERM_DELETE_SUPPLIERS: "Supprimer un fournisseur",

  PERM_VIEW_MODULE_CONTACTS: "Accéder au module contacts",
  PERM_VIEW_ANY_CONTACTS: "Voir tous contacts",
  PERM_VIEW_CONTACTS: "Voir les détails d'un contact",
  PERM_CREATE_CONTACTS: "Créer un contact",
  PERM_EDIT_CONTACTS: "Modifier les contacts",
  PERM_DELETE_CONTACTS: "Supprimer un contact",

  PERM_VIEW_MODULE_PAYMENTS: "Accéder au module paiements",
  PERM_VIEW_ANY_PAYMENTS: "Voir tous les paiements",
  PERM_VIEW_PAYMENTS: "Voir les détails d'un paiement",
  PERM_CREATE_PAYMENTS: "Créer un paiement",

  PERM_VIEW_MODULE_SUBSCRIPTIONS: "Accéder au module inscriptions",
  PERM_VIEW_ANY_SUBSCRIPTIONS: "Voir toutes les inscriptions",
  PERM_VIEW_SUBSCRIPTIONS: "Voir les détails d'une inscription",
  PERM_CREATE_SUBSCRIPTIONS: "Créer une inscription",
  PERM_EDIT_SUBSCRIPTIONS: "Modifier les inscriptions",
  PERM_DELETE_SUBSCRIPTIONS: "Supprimer une inscription",

  PERM_VIEW_MODULE_PACKAGES: "Accéder au module forfaits",
  PERM_VIEW_ANY_PACKAGES: "Voir tous les forfaits",
  PERM_VIEW_PACKAGES: "Voir les détails d'un forfait",
  PERM_CREATE_PACKAGES: "Créer un forfait",
  PERM_EDIT_PACKAGES: "Modifier les forfaits",
  PERM_DELETE_PACKAGES: "Supprimer un forfait",

  PERM_VIEW_MODULE_CUSTOMERS: "Accéder au module clients",
  PERM_VIEW_ANY_CUSTOMERS: "Voir tous les clients",
  PERM_VIEW_CUSTOMERS: "Voir les détails d'un client",
  PERM_CREATE_CUSTOMERS: "Créer un client",
  PERM_EDIT_CUSTOMERS: "Modifier les clients",
  PERM_DELETE_CUSTOMERS: "Supprimer un client",

  PERM_VIEW_MODULE_INVOICES: "Accéder au module factures",
  PERM_VIEW_ANY_INVOICES: "Voir toutes les factures",
  PERM_VIEW_INVOICES: "Voir les détails d'une facture",
  PERM_CREATE_INVOICES: "Créer une facture",
  PERM_EDIT_INVOICES: "Modifier les factures",
  PERM_DELETE_INVOICES: "Supprimer une facture",

  PERM_VIEW_MODULE_PRODUCTS: "Accéder au module produits",
  PERM_VIEW_ANY_PRODUCTS: "Voir tous les produits",
  PERM_VIEW_PRODUCTS: "Voir les détails d’un produit",
  PERM_CREATE_PRODUCTS: "Créer un produit",
  PERM_EDIT_PRODUCTS: "Modifier les produits",
  PERM_DELETE_PRODUCTS: "Supprimer les produits",

  PERM_VIEW_MODULE_ORGANIZATIONS: "Accéder au module organisations",
  PERM_VIEW_ANY_ORGANIZATIONS: "Voir toutes les organisations",
  PERM_VIEW_ORGANIZATIONS: "Voir les détails des organisations",
  PERM_CREATE_ORGANIZATIONS: "Créer une organisation",
  PERM_EDIT_ORGANIZATIONS: "Modifier les organisations",
  PERM_EDIT_OWN_ORGANIZATIONS: "Modifier son organisation",
  PERM_VIEW_OWN_ORGANIZATIONS: "Voir les details de son organisation",
  PERM_DELETE_ORGANIZATIONS: "Supprimer les organisations",

  PERM_VIEW_MODULE_RESELLERS: "Accéder au module revendeurs",
  PERM_VIEW_ANY_RESELLERS: "Voir tous les revendeurs",
  PERM_VIEW_RESELLERS: "Voir les détails d’un revendeur",
  PERM_CREATE_RESELLERS: "Créer un revendeur",
  PERM_EDIT_RESELLERS: "Modifier les revendeurs",
  PERM_EDIT_OWN_RESELLERS: "Modifier son revendeur",
  PERM_DELETE_RESELLERS: "Supprimer les revendeurs",

  PERM_VIEW_MODULE_USERS: "Accéder au module utilisateurs",
  PERM_VIEW_ANY_USERS: "Voir tous les utilisateurs",
  PERM_VIEW_USERS: "Voir les détails d’un utilisateur",
  PERM_CREATE_USERS: "Créer un utilisateur",
  PERM_EDIT_USERS: "Modifier les utilisateurs",
  PERM_DELETE_USERS: "Supprimer les utilisateurs",

  PERM_VIEW_MODULE_ROLES: "Accéder au module rôles",
  PERM_VIEW_ANY_ROLES: "Voir tous les rôles",
  PERM_VIEW_ROLES: "Voir les détails d’un rôle",
  PERM_CREATE_ROLES: "Créer un rôle",
  PERM_EDIT_ROLES: "Modifier les rôles",
  PERM_DELETE_ROLES: "Supprimer les rôles",

  PERM_VIEW_ANY_PERMISSIONS: "Voir toutes les permissions",
  PERM_VIEW_PERMISSIONS: "Voir les détails des permissions",

  PERM_VIEW_ANY_LOGS: "Voir tous les logs",
  PERM_VIEW_LOGS: "Voir les détails d'un log",

  PERM_VIEW_ANY_REQUEST_LOGS: "Voir tous les appels api",
  PERM_VIEW_REQUEST_LOGS: "Voir les details des appels api",
  PERM_DELETE_REQUEST_LOGS: "Supprimer les appels api",

  PERM_VIEW_APP_ADMIN: "Voir l'app admin",
  PERM_VIEW_APP_CRM: "Voir l'app crm",
  PERM_VIEW_APP_FILE_EXPLORER: "Voir l'app explorateur de fichiers",
  PERM_VIEW_APP_PURCHASES: "Voir l'app achats",
  PERM_VIEW_APP_SALES: "Voir l'app ventes",
  PERM_VIEW_APP_DEFAULT: "Voir l'app par défaut",
  PERM_VIEW_APP_INVENTORY: "Voir l'app inventaire",
  PERM_VIEW_APP_RESELLER: "Voir l'app revendeur",
  PERM_VIEW_APP_INSPECTPRO: "Voir l'app inspecpros",
  PERM_VIEW_APP_VEHICLES: "Voir l’app véhicules",

  PERM_APPROVE_ESTIMATIONS: "Approuver les estimations",
  PERM_ASSIGN_ESTIMATIONS: "Assigner les estimations",
  PERM_CANCEL_ESTIMATIONS: "Annuler les estimations",
  PERM_COMPLETE_ESTIMATIONS: "Compléter les estimations",
  PERM_CREATE_ESTIMATIONS: "Créer les estimations",
  PERM_VALID_ESTIMATIONS: "Valider les estimations",
  PERM_DELETE_ESTIMATIONS: "Supprimer les estimations",
  PERM_DENY_ESTIMATIONS: "Refuser les estimations",
  PERM_EDIT_ESTIMATIONS: "Modifier les estimations",
  PERM_REVIEW_ESTIMATIONS: "Revoir les estimations",
  PERM_VIEW_ANY_ESTIMATIONS: "Voir toutes les estimations",
  PERM_VIEW_ESTIMATIONS: "Voir les details des estimations",
  PERM_VIEW_MODULE_ESTIMATIONS: "Accéder au module estimations",
  PERM_EDIT_ESTIMATIONS_COMPONENTS: "Modifier les pièces de l'estimation",
  PERM_EDIT_ESTIMATIONS_TIMES: "Modifier les temps de l'estimation",
  PERM_TAKE_OVER_ESTIMATIONS: "S’approprier une estimation",
  PERM_CHANGE_ESTIMATIONS_MANAGER: "Changer le responsable d’une estimation",
  PERM_MAKE_REPAIR_WITHOUT_ESTIMATIONS: "Faire la reparation sans estimation",

  PERM_VIEW_ANY_COMMENTS: "Voir tout les commentaires",
  PERM_VIEW_COMMENTS: "Voir un commentaire",
  PERM_CREATE_COMMENTS: "Ajouter des commentaires",
  PERM_EDIT_COMMENTS: "Modifier un commentaire",
  PERM_DELETE_COMMENTS: "Supprimer un commentaire",

  PERM_VIEW_MODULE_OPTIONS: "Accéder au module paramètres",
  PERM_VIEW_ANY_OPTIONS: "Voir les paramètres",
  PERM_EDIT_OPTIONS: "Modifier les paramètres",

  PERM_VIEW_MODULE_TAXES: "Accéder au module taxes",
  PERM_VIEW_ANY_TAXES: "Voir tous les taxes",
  PERM_VIEW_TAXES: "Voir les détails d'une taxe",
  PERM_CREATE_TAXES: "Créer une taxe",
  PERM_EDIT_TAXES: "Modifier les taxes",
  PERM_DELETE_TAXES: "Supprimer une taxe",

  PERM_VIEW_MODULE_REPAIRS: "Accéder au module réparation",
  PERM_VIEW_ANY_REPAIRS: "Voir tous les réparations",
  PERM_VIEW_REPAIRS: "Voir les détails d’une réparation",
  PERM_VIEW_ASSIGNED_TO_GROUP_REPAIRS:
    "Voir les réparations assignées à son groupe",
  PERM_CREATE_REPAIRS: "Créer une réparation",
  PERM_EDIT_REPAIRS: "Modifier une réparation",
  PERM_DELETE_REPAIRS: "Supprimer une réparation",
  PERM_VALIDATE_REPAIRS: "Valider une réparation",
  PERM_ASSIGN_REPAIRS: "Assigner une réparation",
  PERM_CANCEL_REPAIRS: "Annuler une réparation",
  PERM_START_REPAIRS: "Débuter une réparation",
  PERM_COMPLETE_REPAIRS: "Completer/Mettre fin a une réparation",
  PERM_TAKE_OVER_REPAIRS: "S’approprier une réparation",
};
