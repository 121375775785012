export default {
  INSPECTIONS_LIST: "Liste des inspections",
  ADD_INSPECTION: "Ajouter une inspection",
  INSPECTION_ADDED: "Inspection ajoutée",
  EDIT_INSPECTION: "Modifier une inspection",
  INSPECTION_UPDATED: "Inspection mise à jour",
  DELETE_THIS_INSPECTION: "Supprimer cette Inspection?",
  INSPECTION_DELETED: "Inspection supprimée",
  EXPIRATION_TIME: "Date d'expiration",
  INSPECTION_TYPE: "Type",
  INSPECTION_TYPE_INSPECTIONS: "Inspection",
  EXPIRATION_DATE: "Date limite",
  CREATOR: "Créateur",
  INSPECTION_STATUS_DRAFT: "Brouillon",
  INSPECTION_STATUS_CANCELED: "Annulée",
  INSPECTION_STATUS_COMPLETED: "Complétée",
  INSPECTION_STATUS_ASSIGNED: "à faire",
  INSPECTION_STATUS_APPROVED: "Validée",
  INSPECTION_STATUS_PENDING: "à assigner",
  INSPECTION_STATUS_REPAIRING: "En reparation",
  INSPECTION_STATUS_IN_PROGRESS: "En cours",
  DO_INSPECTION: "Faire l'inspection",
  REDO_INSPECTION: "Refaire l'inspection",
  COMMENT: "Commentaire",
  IMAGES: "Images",
  CLOSE_INSPECTION: "Fermer l'inspection",
  INSPECTION_SUMMARY: "Résumé",
  NO_VALUE: "Non renseigné",
  TOTAL_POINTS: "Total points",
  CLOSE_INSPECTION_REQUEST: "Finaliser la demande d'inspection",
  SHOW_REQUEST: "Voir la demande",
  CANCEL_INSPECTION: "Annuler l'inspection",
  INSPECTION_CANCELED: "Inspection annulée",
  CANCEL_THIS_INSPECTION: "Voulez-vous annuler cette inspection?",
  COMPLETE_INSPECTION: "Soumettre l'inspection",
  INSPECTION_COMPLETED: "Inspection terminée",
  VIEW_INSPECTION: "Inspection",
  NO_INSPECTION: "Aucune inspection effectuée",
  SAVE_VEHICLE: "Enregistrer le véhicule",
  INSPECTION_DONE: "Inspection effectuée",
  TYPE: "Type",
  NEXT_STEP: "Prochaine étape",
  PREV_STEP: "Étape précédente",
  ALL: "Toutes",
  GENERATE_REPORT: "Générer un rapport",
  RESET_SECTION_FIELDS: "Réinitialiser",
  VALIDATE_ALL_SECTION_FIELDS: "Tout conforme",
  ADD_COMMENT: "Ajouter un commentaire",
  ADD_PICTURES: "Ajouter des photos",
  TO_REPLACE: "À remplacer",
  ERRORS_UNTOUCHED_FIELD_X: "Le champ {fieldName} doit être renseigné",
  END_FORM: "Terminer l'inspection",
  APPROVED_AT: "Validée le",
  APPROVED_BY: "Validée par",
  ASSIGNED_BY: "Assignée par",
  CANCELED_BY: "Annulée par",
  CANCELED_AT: "Annulée le",
  COMPLETED_BY: "Complétée par",
  ASSIGNED_TO: "Assignée à",
  ASSIGNED_TO_GROUP: "Assignée au groupe",
  ASSIGNED_AT: "Assignée le",
  ASSIGNED_TO_ME: "Assignées à moi",
  CREATED_BY_ME: "Créées par moi",
  COMPLETED_AT: "Complétée le",
  APPROVE_INSPECTION: "Valider l'inspection?",
  APPROVE_THIS_INSPECTION: "Voulez-vous valider cette inspection?",
  ASSIGN_THIS_INSPECTION: "Voulez-vous assigner cette inspection?",
  INSPECTION_APPROVED: "Inspection validée",
  ASSIGN_INSPECTION: "Assigner l'inspection",
  INSPECTION_ASSIGNED: "Inspection assignée",
  DEFINE_ACTION: "Définir l'action",
  INSPECTION_ACTION_TO_REPLACE: "À remplacer",
  INSPECTION_ACTION_TO_REPAIR: "À réparer",
  INSPECTION_ACTION_TO_SCHEDULE: "À prévoir",
  INSPECTION_ACTION_TO_VERIFY: "À vérifier",
  INSPECTION_ACTION_TO_MONITOR: "À surveiller",
  INSPECTION_ACTION_SEEPAGE: "Suintage",
  INSPECTION_ACTION_VIBRATIONS: "Vibrations",
  INSPECTION_ACTION_TO_RECIFY: "À rectifier",
  INSPECTION_ACTION_RECOMMENDED_MAINTENANCE: "Maintenance recommandée",
  BATTERY_CHARGE: "Charge",
  AMPS: "AMPS",
  VOLTS: "VOLTS",
  UNIT: "Unité",
  START_INSPECTION: "Démarrer l'inspection",
  START_THIS_INSPECTION: "Voulez-vous démarrer cette inspection?",
  INSPECTION_STARTED: "Inspection commencée",
  STARTED_AT: "Débutée le",
  STARTED_BY: "Débutée par",
  TAKE_OVER_THIS_INSPECTION:
    "Cette inspection est en cours par {name}. \n Voulez-vous vous l’approprier?",
  TAKE_OVER_INSPECTION: "S’approprier l’inspection",
  INSPECTION_TOOK_OVER: "Appropriation <u>effectuée</u>",
  STANDARD: "Standard",
  OFF_ROAD: "Hors route",
  SUPER_SPORT: "Super sport",
  SUMMER: "Été",
  WINTER: "Hiver",
  FOUR_SEASONS: "Quatre saisons",
  TIRE_TYPE: "Type",
  TIRE_SEASON: "Saison",
  INSPECTION_FORM: "Formulaire d’inspection",
  INSPECTION_FORMS_SELECTOR: "Sélectionner le type d’inspection",
  NOT_STANDARD: "Non-standard",
  RESET: "Réinitialiser",
  ALL_GOOD: "Tout conforme",
  RESET_FIELD_X: "Réinitialiser le champ {field}",
  SET_GOOD_X: "Définir conforme le champ {field}",
  INSPECTORS: "Inspecteurs",
  INSPECTOR: "Inspecteur",
  ADD_INSPECTOR: "Ajouter un inspecteur",
  INSPECTION_HAS_UNTOUCHED: "Certains champs n’ont pas été renseignés",
  FILL_FIELD: "Allez remplir",
  SIGN_AND_SEND: "Signer et envoyer",
  REMINDERS_ALERTS: "Avez-vous bien vérifié les informations à envoyer?",
  MODIFY: "Modifier",
  LEAVE_A_COMMENT: "Laisser un commentaire",
  VEHICLE_SCORE: "Note du véhicule",
  SCORE: "Scores",
  COMPLETED: "Complétée",
  COMPLETE: "Terminer",
  SIGN: "Signer",
  APPLY_SIGNATURE_AND_CONTINUE: "Appliquer votre signature et continuer",
  CLEAR: "Effacer",
  SIGNATURE_REQUIRED: "La signature est obligatoire",
  COMPLETE_INFORMATIONS_SENT: "Les informations ont été envoyées avec succès",
  CREATE_ESTIMATE: "Créer une estimation",
  IN_PROGRESS_WARNING: "Cette inspection est en cours actuellement.",
  SELECT_APPROVER: "Sélectionner un approbateur",
  ASSIGNE_TO_INSPECTORS: "Assigner des inspecteurs pour l’inspection",
  YOU_MUST_CHECK_ALL_BOXES: "Vous devez cocher tous les éléments",

  INSPECTION_ALREADY_EXIST: "Une inspection est déjà en cours pour ce véhicule",
  SHOW_INSPECTION: "Voir l’inspection",
  SHOW_ESTIMATION: "Voir l’estimation",
  SHOW_VEHICLE: "Voir le véhicule",

  MECHANICS: "Mécaniciens",
  OFFICIAL_MECHANIC: "Mécanicien officiel",
  CODE: "Inspection",
  CREATED_AT: "Créée le",
  ASSIGNED: "Assignées",
  CANCELED: "Annulées",
  UPDATED_AT: "Mise à jour",
  UPDATE: "Modifier l’inspection",

  YES_TAKE_OVER: "Oui, m’approprier l’inspection",

  EXCERPT: "Instructions/commentaires",
  SHARED: "Partagée",

  REDO_WARNING:
    "Cette action annulera l'inspection actuelle ainsi que l’estimation et la réparation qui en découlent.",

  CHOOSE_INSPECTOR: "Choisir un inspecteur",
  PICTURE_COUNT: "Photos",
  REPAIRED: "réparée",
};
