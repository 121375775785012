export default {
  REPAIRS_LIST: "Repairs List",
  REPAIR: "Repair",
  ADD_REPAIR: "Add Repair",
  VIEW_REPAIR: "View Repair",
  EDIT_REPAIR: "Edit Repair",
  STOCK_NIV: "Stock / VIN",
  CLIENT_WORK_ORDER: "Client / Work Order",
  MODIFY: "Modify",
  DAYS: "Day(s)",
  REPAIR_STATUS_PENDING: "to assign",
  REPAIR_STATUS_IN_PROGRESS: "In Progress",
  REPAIR_STATUS_DRAFT: "Draft",
  REPAIR_STATUS_CANCELED: "Canceled",
  REPAIR_STATUS_COMPLETED: "Completed",
  REPAIR_STATUS_ASSIGNED: "to do",
  REPAIR_STATUS_VALIDATED: "Validated",
  REPAIR_STATUS_APPROVED: "Approved",
  REPAIR_STATUS_REVIEWED: "Reviewed",
  REPAIR_STATUS_DENIED: "Denied",
  CANCEL_REPAIR: "Canceled",
  CANCEL_THIS_REPAIR: "Do you want to cancel this repair?",
  REPAIR_CANCELED: "Repair canceled",
  CANCELED_BY: "Canceled by",
  CANCELED_AT: "Canceled on",
  SHOW_REPAIR: "View Repair",
  VALIDATE_THIS_REPAIR: "Do you want to validate this repair?",
  REPAIR_VALIDATED: "Repair validated",
  REPAIR_ASSIGNED: "Repair assigned",
  REPAIR_UPDATED: "Repair updated",
  REPAIR_ADDED: "Repair added",
  COMPLETED_BY: "Completed by",
  CREATOR: "Created by",
  DELETE_THIS_REPAIR: "Do you want to delete this repair?",
  REPAIR_DELETED: "Repair deleted",
  CREATED_AT: "Created on",
  ASSIGN_REPAIR: "Assign Repair",
  REPAIRERS: "Repairers",
  ASSIGN_TO_REPAIRERS: "Assign to repairers",
  ADD_REPAIRER: "Add Repairer",
  ASSIGN_THIS_REPAIR: "Do you want to assign this repair?",
  REPAIR_ALREADY_EXIST: "A repair is already in progress for this vehicle",
  VALIDATED_BY: "Validated by",
  VALIDATED_AT: "Validated on",
  ASSIGNED_BY: "Assigned by",
  ASSIGNED_TO: "Assigned to",
  ASSIGNED_TO_GROUP: "Assigned to group",
  ASSIGNED_AT: "Assigned on",
  ASSIGNED_TO_ME: "Assigned to me",
  REPAIR_DETAIL: "Repair Details",
  REPAIR_ITEM_PART_NUMBER: "Part Number",
  REPAIR_CLIENT: "Client",
  REPAIR_DEPARTMENT: "Department",
  REPAIR_ALL_DEPARTMENTS: "All Departments",
  REPAIR_ASSIGN_TO: "Assigned to",
  REPAIR_TYPE: "Repair Type",
  REPAIR_DETAIL: "Repair Details",
  REPAIR_ITEM: "Item",
  REPAIR_ITEM_DETAIL: "Detail",
  REPAIR_ITEM_STATE: "State",
  REPAIR_ITEM_PART: "Part",
  REPAIR_ITEM_PART_NUMBER: "Part Number",
  REPAIR_ITEM_MANUAL_LABOR: "Manual Labor",
  REPAIR_ITEM_TIME: "Time",
  REPAIR_ITEM_HOURLY_RATE: "Hourly Rate",
  REPAIR_ITEM_APPROVED: "Approve",
  REPAIR_ITEM_REVIEW: "Review",
  REPAIR_ITEM_REFUSED: "Refuse",
  REPAIR_ITEM_STATUS_APPROVED: "Approved",
  REPAIR_ITEM_STATUS_REVIEWED: "Reviewed",
  REPAIR_ITEM_STATUS_DENIED: "Denied",
  REPAIR_ITEM_CONDITION_TO_REPLACE: "To Replace",
  TO_REPLACE: "To Replace",
  TO_REPAIR: "To Repair",
  REPAIR_ITEM_CONDITION_TO_VERIFY: "To verify",
  TO_VERIFY: "To verify",
  REPAIR_ITEM_CONDITION_TO_MONITOR: "To monitor",
  TO_MONITOR: "To monitor",
  REPAIR_ITEM_CONDITION_TO_SCHEDULE: "To schedule",
  TO_SCHEDULE: "To schedule",
  REPAIRED: "Repaired",

  START_REPAIR: "Start Repair",
  START_THIS_REPAIR: "Do you want to start the repair?",
  REPAIR_STARTED: "Repair started",
  STARTED_AT: "Started on",
  STARTED_BY: "Started by",

  END_REPAIR: "End Repair",
  END_THIS_REPAIR: "Do you want to end the repair?",
  REPAIR_ENDED: "Repair ended",
  ENDED_AT: "Ended at",
  COMPLETED_AT: "Completed at",
  ENDED_BY: "Ended by",

  REPAIR_ITEM_ADDED: "Item added",
  REPAIR_ITEM_DELETED: "Item deleted",
  OTHER_WORK_ON_REPAIR: "Someone else is already working on this repair",
  TAKE_OVER_REPAIR: "Take over the repair",
  TAKE_OVER_THIS_REPAIR:
    "This repair is currently being worked on by {name}. \n Do you want to take it over?",
  YES_TAKE_OVER: "Yes, take over the repair",
  REPAIR_TOOK_OVER: "Takeover <u>completed</u>",
  SHOW_REPAIR: "View Repair",
  DELETE_THIS_ITEM_FROM_REPAIR:
    "Do you want to delete this item from the repair?",
  ALL: "All",

  CHOOSE_REPAIRER: "Choose a repairer",
  UPDATE: "Update repair",
};
