import service from "@/store/services/estimations-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  estimation: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, estimation) => {
    state.estimation = estimation;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((estimation) => {
      commit("SET_RESOURCE", estimation);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((estimation) => {
      commit("SET_RESOURCE", estimation);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((estimation) => {
      commit("SET_RESOURCE", estimation);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  cancel({ commit, dispatch }, params) {
    return service.cancel(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  valid({ commit, dispatch }, params) {
    return service.valid(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  startComponents({ commit, dispatch }, params) {
    return service.startComponents(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  endComponents({ commit, dispatch }, params) {
    return service.endComponents(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  startTimes({ commit, dispatch }, params) {
    return service.startTimes(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  endTimes({ commit, dispatch }, params) {
    return service.endTimes(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  startApprove({ commit, dispatch }, params) {
    return service.startApprove(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  endApprove({ commit, dispatch }, params) {
    return service.endApprove(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  redoApprove({ commit, dispatch }, params) {
    return service.redoApprove(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  deny({ commit, dispatch }, params) {
    return service.deny(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  review({ commit, dispatch }, params) {
    return service.review(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  deleteItem({ commit, dispatch }, { itemId }) {
    return service.deleteItem(itemId, this.$axios);
  },

  takeOverComponents({ commit, dispatch }, { estimationId, data }) {
    return service
      .takeOverComponents(estimationId, data, this.$axios)
      .then((request) => {
        commit("SET_RESOURCE", request);
      });
  },

  takeOverTimes({ commit, dispatch }, { estimationId, data }) {
    return service
      .takeOverTimes(estimationId, data, this.$axios)
      .then((request) => {
        commit("SET_RESOURCE", request);
      });
  },

  review({ commit, dispatch }, params) {
    return service.review(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  byPass({ commit, dispatch }, params) {
    return service.byPass(params, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  sendClientApprove({ commit, dispatch }, { id, data }) {
    console.log({ id });
    return service.sendClientApprove(id, data, this.$axios).then((request) => {
      // commit("SET_RESOURCE", request);
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  estimation: (state) => state.estimation,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((estimation) => ({
      id: estimation.id,
      code: estimation.code,
    }));
  },
};

const estimations = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default estimations;
