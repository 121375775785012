export default {
  INSPECTIONS_LIST: "Inspection List",
  ADD_INSPECTION: "Add Inspection",
  INSPECTION_ADDED: "Inspection Added",
  EDIT_INSPECTION: "Edit Inspection",
  INSPECTION_UPDATED: "Inspection Updated",
  DELETE_THIS_INSPECTION: "Delete this Inspection?",
  INSPECTION_DELETED: "Inspection Deleted",
  EXPIRATION_TIME: "Expiration Time",
  INSPECTION_TYPE: "Inspection Type",
  INSPECTION_TYPE_INSPECTIONS: "Inspection",
  EXPIRATION_DATE: "Expiration Date",
  CREATOR: "Creator",
  INSPECTION_STATUS_DRAFT: "Draft",
  INSPECTION_STATUS_CANCELED: "Canceled",
  INSPECTION_STATUS_COMPLETED: "Completed",
  INSPECTION_STATUS_ASSIGNED: "to do",
  INSPECTION_STATUS_APPROVED: "Approved",
  INSPECTION_STATUS_PENDING: "to assign",
  INSPECTION_STATUS_REPAIRING: "Repairing",
  INSPECTION_STATUS_IN_PROGRESS: "In Progress",
  DO_INSPECTION: "Perform Inspection",
  REDO_INSPECTION: "Redo Inspection",
  COMMENT: "Comment",
  IMAGES: "Images",
  CLOSE_INSPECTION: "Close Inspection",
  INSPECTION_SUMMARY: "Inspection Summary",
  NO_VALUE: "Not Specified",
  TOTAL_POINTS: "Total Points",
  CLOSE_INSPECTION_REQUEST: "Finalize Inspection Request",
  SHOW_REQUEST: "View Request",
  CANCEL_INSPECTION: "Cancel Inspection",
  INSPECTION_CANCELED: "Inspection Canceled",
  CANCEL_THIS_INSPECTION: "Do you want to cancel this inspection?",
  COMPLETE_INSPECTION: "Submit Inspection",
  INSPECTION_COMPLETED: "Inspection Completed",
  VIEW_INSPECTION: "View Inspection",
  NO_INSPECTION: "No inspections performed",
  SAVE_VEHICLE: "Save Vehicle",
  INSPECTION_DONE: "Inspection Done",
  TYPE: "Type",
  NEXT_STEP: "Next Step",
  PREV_STEP: "Previous Step",
  ALL: "All",
  GENERATE_REPORT: "Generate Report",
  RESET_SECTION_FIELDS: "Reset Section Fields",
  VALIDATE_ALL_SECTION_FIELDS: "All Valid",
  ADD_COMMENT: "Add Comment",
  ADD_PICTURES: "Add Pictures",
  TO_REPLACE: "To Replace",
  ERRORS_UNTOUCHED_FIELD_X: "The field {fieldName} is required",
  END_FORM: "End Inspection",
  APPROVED_AT: "Approved on",
  APPROVED_BY: "Approved by",
  ASSIGNED_BY: "Assigned by",
  CANCELED_BY: "Canceled by",
  CANCELED_AT: "Canceled on",
  COMPLETED_BY: "Completed by",
  ASSIGNED_TO: "Assigned to",
  ASSIGNED_TO_GROUP: "Assigned to group",
  ASSIGNED_AT: "Assigned on",
  ASSIGNED_TO_ME: "Assigned to me",
  CREATED_BY_ME: "Created by me",
  COMPLETED_AT: "Completed on",
  APPROVE_INSPECTION: "Approve Inspection?",
  APPROVE_THIS_INSPECTION: "Do you want to approve this inspection?",
  ASSIGN_THIS_INSPECTION: "Do you want to assign this inspection?",
  INSPECTION_APPROVED: "Inspection Approved",
  ASSIGN_INSPECTION: "Assign Inspection",
  INSPECTION_ASSIGNED: "Inspection Assigned",
  DEFINE_ACTION: "Define Action",
  INSPECTION_ACTION_TO_REPLACE: "To Replace",
  INSPECTION_ACTION_TO_REPAIR: "To Repair",
  INSPECTION_ACTION_TO_SCHEDULE: "To Schedule",
  INSPECTION_ACTION_TO_VERIFY: "To Verify",
  INSPECTION_ACTION_TO_MONITOR: "To Monitor",
  INSPECTION_ACTION_SEEPAGE: "Leakage",
  INSPECTION_ACTION_VIBRATIONS: "Vibrations",
  INSPECTION_ACTION_TO_RECIFY: "To Rectify",
  INSPECTION_ACTION_RECOMMENDED_MAINTENANCE: "Recommended Maintenance",
  BATTERY_CHARGE: "Charge",
  AMPS: "AMPS",
  VOLTS: "VOLTS",
  UNIT: "Unit",
  START_INSPECTION: "Start Inspection",
  START_THIS_INSPECTION: "Do you want to start this inspection?",
  INSPECTION_STARTED: "Inspection Started",
  STARTED_AT: "Started on",
  STARTED_BY: "Started by",
  TAKE_OVER_THIS_INSPECTION:
    "This inspection is currently in progress by {name}. \n Do you want to take over?",
  TAKE_OVER_INSPECTION: "Take Over Inspection",
  INSPECTION_TOOK_OVER: "Taken over",
  STANDARD: "Standard",
  OFF_ROAD: "Off-road",
  SUPER_SPORT: "Super Sport",
  SUMMER: "Summer",
  WINTER: "Winter",
  FOUR_SEASONS: "Four Seasons",
  TIRE_TYPE: "Tire Type",
  TIRE_SEASON: "Tire Season",
  INSPECTION_FORM: "Inspection Form",
  INSPECTION_FORMS_SELECTOR: "Select Inspection Type",
  NOT_STANDARD: "Non-standard",
  RESET: "Reset",
  ALL_GOOD: "All Good",
  RESET_FIELD_X: "Reset field {field}",
  SET_GOOD_X: "Set field {field} as good",
  INSPECTORS: "Inspectors",
  INSPECTOR: "Inspector",
  ADD_INSPECTOR: "Add Inspector",
  INSPECTION_HAS_UNTOUCHED: "Some fields have not been filled",
  FILL_FIELD: "Please fill in",
  SIGN_AND_SEND: "Sign and Send",
  REMINDERS_ALERTS: "Have you checked the information to be sent?",
  MODIFY: "Modify",
  LEAVE_A_COMMENT: "Leave a comment",
  VEHICLE_SCORE: "Vehicle Score",
  SCORE: "Scores",
  COMPLETED: "Completed",
  COMPLETE: "Complete",
  SIGN: "Sign",
  APPLY_SIGNATURE_AND_CONTINUE: "Apply your signature and continue",
  CLEAR: "Clear",
  SIGNATURE_REQUIRED: "Signature is required",
  COMPLETE_INFORMATIONS_SENT: "Information has been sent successfully",
  CREATE_ESTIMATE: "Create Estimate",
  IN_PROGRESS_WARNING: "This inspection is currently in progress.",
  SELECT_APPROVER: "Select an approver",
  ASSIGNE_TO_INSPECTORS: "Assign inspectors for the inspection",
  YOU_MUST_CHECK_ALL_BOXES: "You must check all items",

  INSPECTION_ALREADY_EXIST:
    "An inspection is already in progress for this vehicle",
  SHOW_INSPECTION: "View Inspection",
  SHOW_ESTIMATION: "View Estimation",
  SHOW_VEHICLE: "View Vehicle",

  MECHANICS: "Mechanics",
  OFFICIAL_MECHANIC: "Official Mechanic",
  CODE: "Inspection",
  CREATED_AT: "Created at",
  ASSIGNED: "Assigned",
  CANCELED: "Canceled",
  UPDATED_AT: "Updated at",
  UPDATE: "Update inspection",

  YES_TAKE_OVER: "Yes, take over the inspection",

  EXCERPT: "Instructions/Comments",
  SHARED: "Shared",

  REDO_WARNING:
    "This action will cancel the current inspection as well as the resulting estimate and repair.",

  CHOOSE_INSPECTOR: "Choose an inspector",
  PICTURE_COUNT: "Pictures",
  REPAIRED: "repaired",
};
